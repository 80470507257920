import React from 'react';
import type {Node} from 'react';
import unliked from "../assets/like-3@2x.png";
import liked from "../assets/like1@2x.png";
import addedToCart from "../assets/added@2x.png";
import addToCart from "../assets/add-2@2x.png";
import zotaLogo from "../assets/zotaLogo.svg";
import AliceCarousel from 'react-alice-carousel';

export const ProductBox = ({myText, myPrice, myimgs, myDescription, setSelectedContent, setSelectedProduct, cartObject, setCartObject, likedContent, setLikedContent}): Node =>{
    const newSlidesArray = [];
  
    myimgs.forEach((thisImage, idx) => {
      newSlidesArray.push(

          <img  src={thisImage} 
            alt={""} 
            key={idx} 
            style={{  
              width: 165,
              height: 290,
            }}
          />)
    }); 
    
    return(        
      <div
        style={{
          marginTop: 50,
        }}>
          <div
            style={{
            backgroundColor: 'white',
            width: "99%",
          }}>
            <div
              style={{
                backgroundColor: 'white',
                width: 340,
                height: 300,
                marginLeft: "auto",
                marginRight: "auto"
              }}
              className={"carouselWrapper"}
            >
              <AliceCarousel 
                mouseTracking 
                responsive={
                  {
                    0: {
                        items: 1,
                    },
                    360: {
                        items: 2
                    },
                  }
                }
                items={newSlidesArray} 
                autoHeight={false} 
                renderDotsItem={() =>(<></>)}
                renderPrevButton={() =>(<></>)}
                renderNextButton={() =>(<></>)}
                disableDotsControls={true}
                disableButtonsControls={true}
              />
            </div>
          </div>
  
          <div
            style={{
              display: "flex",
              flexWrap: 'wrap',
              flexDirection: 'row',  
              boxShadow: "5px 5px 5px 5px #dddddd",
              borderRadius: 5
            }}
          >
            <div
              style={{
                maxWidth: "20%"
              }}
            >
              <button
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  border: 0,
                  backgroundColor: "white",
                  height: "50%"
                }}
                onClick={() => {
                  setLikedContent(() =>{
                    if(likedContent.find((element) => element.name === myText) === undefined){
                      const newArray = [...likedContent];
                      newArray.push({
                        name: myText,
                        price: myPrice,
                        imgs: myimgs,
                        quantity: 1
                      });
                      return newArray;
                    }else{
                      return likedContent.filter(el => el.name !== myText);
                    }
                  });
                }}  
                >
                  <img
                    style={{
                      alignContent: "top",
                      width: 31,
                      height: 27
                    }}
                    alt={""}
                    src={likedContent.find((element) => element.name === myText) === undefined  ? unliked : liked}
                  />
              </button>
            </div>
            <div 
              style={{
                maxHeight: 50,
                maxWidth: "50%"
              }}
              onClick={() =>{
                setSelectedProduct({
                  name: myText,
                  price: myPrice,
                  imgs: myimgs,
                  quantity: 1,
                  description: myDescription
                });
                setSelectedContent("ProductPage");
              }}
            >
              <span style={{
                fontWeight: "bold",
                fontSize: 10
              }}>{myText.toUpperCase()}</span>
  
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row'          
                  }}
                >
                  <div
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto"
                    }}
                  >
                    <img
                      style={{
                          width: 8,
                          height: 10
                      }}
                      alt={""}
                      src={zotaLogo}
                    />
                  </div>
                  <p style={{
                    fontSize: 10,
                    marginLeft: 5,
                    marginTop: "auto",
                    marginBottom: "auto"
                  }}>{myPrice + ' ZotaPoints'}</p>
              </div>        
            </div>
  
            <div
              style={{
                maxWidth: "50%"
              }}
            >
              <button
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  alignContent: 'flex-end',
                  border: 0,
                  backgroundColor: "white",
                }}
                onClick={() => {
                  if(cartObject.find((element) => element.name === myText) === undefined){
                    setCartObject(()=>{
                      const newArray = [...cartObject];
                      newArray.push({
                        name: myText,
                        price: myPrice,
                        quantity: 1,
                        imgs: myimgs
                      });
                      return newArray;
                    });
                  }else{
                    setCartObject((current) => current.filter((element) => element.name !== myText));
                  }
                }}  
                >
                  {cartObject.find((element) => element.name === myText) === undefined 
                  ?
                  (<div
                    style={{
                      marginLeft: 5,
                      display: 'flex',
                      justifyContent: "center",
                      border: 0,
                      borderRadius: 5,
                      background: 'linear-gradient(107deg, #A97FD5, #5093D6)',
                      padding: 2,
                      marginBottom: 7
                    }}              
                  >
                    <img
                      alt={""}
                      style={{
                        width: 25,
                        height: 25
                      }}
                      src={addToCart}
                    />
                    <p style={{
                      marginLeft: 2,
                      fontWeight: 'bold',
                      fontSize: 9,
                      color: 'white',
                      paddingRight:12 
                    }}>ADD TO CART</p>
                  </div>)
                  :
                  (<div
                    style={{
                      marginLeft: 5,
                      display: 'flex',
                      padding: 2,
                      marginBottom: 7
                    }}              
                  >
                    <img
                      alt={""}
                      style={{
                        width: 25,
                        height: 25
                      }}
                      src={addedToCart}
                    />
                    <p style={{
                      marginLeft: 2,
                      fontWeight: 'bold',
                      fontSize: 9,
                      color: '#2A63DB'
                    }}>ADDED TO CART</p>
                  </div>)}
              </button>   
            </div>
          </div>
      </div>
    )
  }