import React from 'react';
import type {Node} from 'react';
import "react-alice-carousel/lib/alice-carousel.css";
import {ProductBox} from "./ProductBox.js";


export const MainPage = ({elements, setSelectedContent, setSelectedProduct, cartObject, setCartObject, likedContent, setLikedContent}): Node =>{
  const items = [];

  elements.forEach(thisElement => {
    items.push(thisElement);
  });

  return(        
    <div
      style={{
        backgroundColor: 'white',
      }}>
      <div
        style={{
          marginTop: 15,
        }}>
        {items.map((value, index) => {
          return <div 
            key={index}         
            style={{
              marginTop: 15,
              backgroundColor: "white", 
              flex: 0.3,
              display: "flex",
              justifyContent: "center"
            }}>
            <ProductBox 
              myText={value.name}
              myPrice={value.price}
              myimgs={value.imgs}
              myDescription={value.description}
              likedContent={likedContent}
              setLikedContent={setLikedContent}
              setSelectedContent={setSelectedContent}
              setSelectedProduct={setSelectedProduct}
              cartObject={cartObject}
              setCartObject={setCartObject}
            ></ProductBox>
          </div>
        })}
      </div>
    </div>
  )
}