import React from 'react';
import type {Node} from 'react';
import barcklaysImage from "../assets/barclays-logo-1@2x.png";
import HSBCImage from "../assets/unnamed@2x.png";
import lloydsImage from "../assets/ed5cf7bb88d7400b064a38a874045fb3@2x.png";
import halifaxImage from "../assets/halifax-1@2x.png";
import satanderImage from "../assets/Path 1462@2x.png";
import theCoOpImage from "../assets/Path 1463@2x.png";
import nationWideImage from "../assets/Path 1464@2x.png";
import rbsImage from "../assets/Path 1465@2x.png";
import bankImage from "../assets/UKFlag.png";
import Select from 'react-select'

export const BankPage = ({setSelectedContent, setSelectedBank}): Node =>{
    const bankList = [
      {
        bankName: "Barclays",
        bankImage: barcklaysImage,
        bankImageDimensionX: 134,
        bankImageDimensionY: 55
      },
      {
        bankName: "HSBC",
        bankImage: HSBCImage,
        bankImageDimensionX: 129,
        bankImageDimensionY: 85
      },
      {
        bankName: "Lloyds",
        bankImage: lloydsImage,
        bankImageDimensionX: 129,
        bankImageDimensionY: 63
      },
      {
        bankName: "Halifax",
        bankImage: halifaxImage,
        bankImageDimensionX: 114,
        bankImageDimensionY: 59
      },
      {
        bankName: "Santander",
        bankImage: satanderImage,
        bankImageDimensionX: 144,
        bankImageDimensionY: 28
      },
      {
        bankName: "The Cooperative",
        bankImage: theCoOpImage,
        bankImageDimensionX: 143,
        bankImageDimensionY: 20
      },
      {
        bankName: "Nation Wide",
        bankImage: nationWideImage,
        bankImageDimensionX: 149,
        bankImageDimensionY: 35
      },
      {
        bankName: "RBS",
        bankImage: rbsImage,
        bankImageDimensionX: 112,
        bankImageDimensionY: 34
      }
    ];

    const customSelectStyles = {
      menu: (provided, state) => ({
        ...provided,
        width: 300,
        borderBottom: '1px dotted pink',
        color: "black",
        padding: 20,
      }),
    
      control: (_, { selectProps: { width }}) => ({
        width: 300,
        display: "flex",
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
        border: "1px solid #dddddd"
      }),
    
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
      }
    }

    return(        
      <div
        style={{
          backgroundColor: 'white',
          display: "flex",
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
          width: "100%",
        }}>
          <div
            style={{
              display: "flex",
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'center',
              width: "100%"
            }}
          >
            <p
            style={{
              textAlign: 'center',
              fontWeight: "bold"
            }}>
              {"SELECT YOUR BANK TO MAKE A DIRECT TRANSFER"}
            </p>    
          </div>

        <div
          style={{
            backgroundColor: 'white',
            display: "flex",
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'center',
            width: "90%",
          }}>
            <Select 
              styles={customSelectStyles}
              options={[
                { value: 'UK', label: <div><img src={bankImage} height="15px" width="26px" alt={""}/>United Kingdom</div> },
                { value: 'Germany', label: 'Germany' },
                { value: 'France', label: 'France' },
                { value: 'Switzerland', label: 'Switzerland' },
                { value: 'Canada', label: 'Canada' },
                { value: 'USA', label: 'USA' },
              ]}
              name={"nationality"}
              defaultValue={{ value: 'UK', label: <div><img src={bankImage} height="15px" width="26px" alt={""}/>United Kingdom</div> }}
            />  
          </div>

        <div
          style={{
            backgroundColor: 'white',
            display: "flex",
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'center'
          }}>
            {bankList.map((value, index) => {
              return <div 
                key={index}         
                style={{
                  marginTop: 15,
                  backgroundColor: "#ffffff", 
                  flexBasis: '50%',
                  minHeight: 100,
                  maxWidth: '50%',
                  display: "flex",
                  flexWrap: 'wrap',                    
                  flexDirection: 'row',
                  justifyContent: "center",
                }}>
                  <div style={{
                    border: "2px solid #dddddd",
                    borderRadius: 10,
                    display: "flex",
                    flexWrap: 'wrap',                    
                    flexDirection: 'row',
                    justifyContent: "center",
                    height: "100%",
                    width: "95%",
                  }}
                  >
                    <button
                      style={{
                        border: 0,
                        backgroundColor: "white",
                        alignContent: 'center',
                      }}
                      onClick={() =>{
                        setSelectedBank(value);
                        setSelectedContent("AccountPage");
                      }}     
                    >
                      <img
                        style={{
                          width: value.bankImageDimensionX,
                          height: value.bankImageDimensionY,
                          alignSelf: 'center',
                          marginTop: 10
                        }}
                        alt={""}
                        src={value.bankImage}
                      />  
                    </button>
                  </div>
              </div>
            })}
        </div>
      </div>
    )
}