import React from 'react';
import type {Node} from 'react';
import zotaLogo from "../assets/zotaLogo.svg";
import zotaWhiteLogo from "../assets/logo@2x.png";

export const PaymentPage = ({cartObject, setSelectedContent, zotaPoints, selectedPaymentAlternative, setSelectedPaymentAlternative}): Node =>{
  const totalPrice = (cartObject.length === 1 ? 
    (cartObject[0].price*cartObject[0].quantity) 
    : 
    ((cartObject.length > 0 ? (
        cartObject.map(function(obj) { return obj.price*obj.quantity; }).reduce((accumulator, currentValue) => accumulator + currentValue)
    ) : (
        0
    )))) - zotaPoints;

    const alternativesList = [
      {
        zotaPoints: 150,
        gbpPoints: 3
      },
      {
        zotaPoints: 300,
        gbpPoints: 5
      },
      {
        zotaPoints: 500,
        gbpPoints: 8
      },
      {
        zotaPoints: 1000,
        gbpPoints: 11
      },
      {
        zotaPoints: 2000,
        gbpPoints: 20
      },
      {
        zotaPoints: 5000,
        gbpPoints: 49
      },
      {
        zotaPoints: 10000,
        gbpPoints: 89
      }
    ];


    return(        
      <div
        style={{
          backgroundColor: 'white',
        }}>
          <div
              style={{
                  marginTop: 8,
                  display: "flex",
                  flexWrap: 'wrap',                    
                  flexDirection: 'row',
                  justifyContent: "center",
              }}              
          >
              <p style={{
                fontSize: 20,
                fontWeight: "bold"
              }}>{"TO FINISH YOUR ORDER YOU NEED" }</p>  
          </div>           

          <div
              style={{
                  marginTop: 20,
                  display: "flex",
                  flexWrap: 'wrap',
                  justifyContent: "center",
              }}              
            >
              <div
                  style={{
                    display: "flex",
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      justifyContent: "center",
                  }}
              >
                  <div
                    style={{
                        margin: "auto"
                    }}
                  >
                    <img
                        style={{
                          width: 16,
                          height: 20
                        }}
                        alt={""}
                        src={zotaLogo}
                    />
                  </div>
                  <span style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#2A63DB",
                    marginLeft: 3,
                    marginBottom: 5
                  }}>{totalPrice}</span>
                  
                  <span style={{
                    fontSize: 16,
                    color: "black",
                    marginLeft: 6,
                    marginBottom: 5
                  }}>{" ZotaPoints"}</span>
              </div>
          </div>   

          <div
              style={{
                  marginTop: 8,
                  display: "flex",
                  flexWrap: 'wrap',                    
                  flexDirection: 'row',
                  justifyContent: "center",
              }}              
          >
              <p>{"Please add points to your wallet now:" }</p>  
          </div>            

          <div
          style={{
            backgroundColor: 'white',
            display: "flex",
            flexWrap: 'wrap',                    
            flexDirection: 'row',
            justifyContent: "center",
          }}>
            {alternativesList.map((value, index) => {
              return <div 
                key={index}         
                style={{
                  backgroundColor: "#ffffff", 
                  flexBasis: '50%',
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: "#aaaaaa",
                  display: "flex",
                  flexWrap: 'wrap',                    
                  flexDirection: 'row',
                  justifyContent: "center",
                }}>
                  <button
                    style={{
                      border: "2px solid #dddddd",
                      borderRadius: 10,
                      height: "80%",
                      width: "80%",
                      minHeight: 100,
                      background: selectedPaymentAlternative === value.zotaPoints ? 'linear-gradient(107deg, #A97FD5, #5093D6)' : 'white',
                    }}
                    onClick={() =>{
                      if(value.zotaPoints >= totalPrice){
                        setSelectedPaymentAlternative(value.zotaPoints);
                      }
                    }}     
                  >
                    <div
                        style={{
                            display: "flex",
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            justifyContent: "center",
                            marginTop: 10,
                        }}
                    >
                        <div
                          style={{
                              marginTop: "auto",
                              marginBottom: "auto"
                          }}
                        >
                            <img
                              style={{
                                width: 16,
                                height: 20
                              }}
                              alt={""}
                              src={selectedPaymentAlternative === value.zotaPoints  ? zotaWhiteLogo : zotaLogo}
                            />
                        </div>
                        <span style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: selectedPaymentAlternative === value.zotaPoints ? "white" : "#2A63DB",
                          marginLeft: 3
                        }}>{value.zotaPoints}</span>
                    </div>       

                    <div
                        style={{
                            display: "flex",
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            justifyContent: "center",
                        }}
                    >
                        <p style={{
                          color: selectedPaymentAlternative === value.zotaPoints ? "white" : "black"
                        }}>{value.gbpPoints + " GBP"}</p>
                    </div>                                
                  </button>
              </div>
            })}

            <div 
              style={{
                backgroundColor: "#ffffff", 
                flexBasis: '50%',
                borderRadius: 10,
                borderWidth: 1,
                borderColor: "#aaaaaa",
                display: "flex",
                flexWrap: 'wrap',                    
                flexDirection: 'row',
                justifyContent: "center",
              }}>
                <button
                  style={{
                    border: "2px solid #dddddd",
                    borderRadius: 10,
                    backgroundColor: "white",
                    height: "80%",
                    width: "80%",
                    minHeight: 100,
                  }}
                  onClick={() =>{
                    setSelectedContent("SpecialPaymentPage");
                  }}     
                >
                  <div
                      style={{
                          display: "flex",
                          flexWrap: 'wrap',
                          flexDirection: 'row',
                          justifyContent: "center",
                          marginTop: 10
                      }}
                  >
                      <p style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: '#2A63DB'
                      }}>{"OTHER"}</p>
                  </div>                                   
                </button>
            </div>            
        </div> 


            <div
              style={{
              backgroundColor: 'white',
              display: "flex",
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 20
            }}>
                <div 
                    style={{
                      marginTop: 7,
                      backgroundColor: "white", 
                      flexBasis: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                }}>                
                    <button
                        style={{
                          width: 158,
                          height: 60,
                          paddingTop: 12,
                          paddingBottom: 12,
                          alignSelf: 'center',
                          border: "2px solid #dddddd",
                          borderRadius: 15,
                          boxShadow: "2px 2px #dddddd",
                          backgroundColor: "white",
                        }}
                        onClick={() => setSelectedContent("MainPage")}  
                    >
                        <p style={{
                            color: '#2A63DB',
                            fontWeight: 'bold',
                            fontSize: 11
                        }}>CONTINUE SHOPPING</p>               
                    </button> 
                </div>

                <div 
                    style={{
                    marginTop: 7,
                    backgroundColor: "white", 
                    flexBasis: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                }}>                
                    <button
                        style={{
                            width: 158,
                            height: 60,
                            paddingTop: 12,
                            paddingBottom: 12,
                            backgroundColor: "white",
                            border: 0,
                            borderRadius: 15,
                            background: 'linear-gradient(107deg, #A97FD5, #5093D6)',
                        }}
                        onClick={() => {
                          if(selectedPaymentAlternative !== -1){
                            setSelectedContent("BankPage");
                          }
                        }}  
                    >
                        <p style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: 11
                        }}>CHECKOUT</p>        
                    </button> 
                </div>

            </div>
      </div>
    )
}