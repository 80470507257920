import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import successAnimation1 from "../assets/Group 381.svg";
import successAnimation2 from "../assets/Group 382.svg";
import successAnimation3 from "../assets/Group 383.svg";
import successAnimation4 from "../assets/Group 384.svg";
import successAnimation5 from "../assets/Group 385.svg";
import successAnimation6 from "../assets/Group 386.svg";


export const SuccessPaymentPage = ({setSelectedContent}): Node =>{
    const [animationCount, setAnimationCount] = useState(0);
    const animationFrames = [
      successAnimation1,
      successAnimation2,
      successAnimation3,
      successAnimation4,
      successAnimation5,
      successAnimation6,
    ];

    useEffect(() => {
      setTimeout(() => {     
        if(animationCount < animationFrames.length){
          setAnimationCount(animationCount + 1);
        }else{
          setSelectedContent("CheckoutPage");
        }
      }, 500)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationCount]);

    return(        
      <div
        style={{
          backgroundColor: 'white',
          maxWidth: window.innerHeight > window.innerWidth ? "100%" : 450,
          overflow: window.innerHeight > window.innerWidth ? 'visible' : 'hidden',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <img
          alt={""}
          style={{
            alignSelf: 'center',
          }}
          src={animationFrames[animationCount]}
        />     
      </div>
    )
}