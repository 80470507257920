import React, {useEffect} from 'react';
import type {Node} from 'react';
import unliked from "../assets/like-3@2x.png";
import liked from "../assets/like1@2x.png";
import addedToCart from "../assets/added@2x.png";
import addToCart from "../assets/add-2@2x.png";
import zotaLogo from "../assets/zotaLogo.svg";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {ProductBox} from "./ProductBox.js";

export const ProductPage = ({elements, selectedProduct, cartObject, setCartObject, likedContent, setLikedContent, setSelectedContent, setSelectedProduct}): Node =>{
  const items = [];

  elements.filter(thisElement => thisElement.name !== selectedProduct.name).forEach(thisElement => {
    items.push(thisElement);
  });

  const newSlidesArray = [];
  selectedProduct.imgs.forEach((thisImage, idx) => {
    newSlidesArray.push(
      <div
        key={idx} 
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <img  src={thisImage} 
          alt={""} 
          style={{  
            width: "100%",
            height: 450,
            objectFit: "cover",
          }}
        />
      </div>)
  }); 
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedProduct]);

    return(        
      <div
      style={{
        backgroundColor: 'white',
        marginTop: 30,
      }}>
        <div
          style={{
            backgroundColor: "white", 
            flex: 0.3,
            display: "flex",
            justifyContent: "center",
        }}>
          <div
            style={{
            backgroundColor: 'white',
            width: 400,
            height: 550
          }}>
            <AliceCarousel
                activeIndex={0}
                items={newSlidesArray} 
            />
          </div>
        </div>

        <div
        style={{
          backgroundColor: "white", 
          flex: 0.3,
          display: "flex",
          justifyContent: "center",
          width: "97%"
        }}
        >
        <div
            style={{
              display: "flex",
              flexWrap: 'wrap',
              flexDirection: 'row',  
            }}
          >
            <div
              style={{
                maxWidth: "20%"
              }}
            >
              <button
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  border: 0,
                  backgroundColor: "white",
                  height: "50%"
                }}
                onClick={() => {
                  setLikedContent(() =>{
                    if(likedContent.find((element) => element.name === selectedProduct.name) === undefined){
                      const newArray = [...likedContent];
                      newArray.push({
                        name: selectedProduct.name,
                        price: selectedProduct.price,
                        imgs: selectedProduct.imgs,
                        quantity: 1
                      });
                      return newArray;
                    }else{
                      return likedContent.filter(el => el.name !== selectedProduct.name);
                    }
                  });
                }}  
                >
                  <img
                    style={{
                      alignContent: "top",
                      width: 31,
                      height: 27
                    }}
                    alt={""}
                    src={likedContent.find((element) => element.name === selectedProduct.name) === undefined  ? unliked : liked}
                  />
              </button>
            </div>
            <div 
              style={{
                maxHeight: 50,
                maxWidth: "50%"
              }}
              onClick={() =>{
                setSelectedProduct({
                  name: selectedProduct.name,
                  price: selectedProduct.price,
                  imgs: selectedProduct.imgs,
                  quantity: 1,
                  description: selectedProduct.description
                });
                setSelectedContent("ProductPage");
              }}
            >
              <span style={{
                fontWeight: "bold",
                fontSize: 10
              }}>{selectedProduct.name.toUpperCase()}</span>
  
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row'          
                  }}
                >
                  <div
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto"
                    }}
                  >
                    <img
                      style={{
                          width: 8,
                          height: 10
                      }}
                      alt={""}
                      src={zotaLogo}
                    />
                  </div>
                  <p style={{
                    fontSize: 10,
                    marginLeft: 5,
                    marginTop: "auto",
                    marginBottom: "auto"
                  }}>{selectedProduct.price + ' ZotaPoints'}</p>
              </div>        
            </div>
  
            <div
              style={{
                maxWidth: "50%"
              }}
            >
              <button
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  alignContent: 'flex-end',
                  border: 0,
                  backgroundColor: "white",
                }}
                onClick={() => {
                  if(cartObject.find((element) => element.name === selectedProduct.name) === undefined){
                    setCartObject(()=>{
                      const newArray = [...cartObject];
                      newArray.push({
                        name: selectedProduct.name,
                        price: selectedProduct.price,
                        quantity: 1,
                        imgs: selectedProduct.imgs
                      });
                      return newArray;
                    });
                  }else{
                    setCartObject((current) => current.filter((element) => element.name !== selectedProduct.name));
                  }
                }}  
                >
                  {cartObject.find((element) => element.name === selectedProduct.name) === undefined 
                  ?
                  (<div
                    style={{
                      marginLeft: 5,
                      display: 'flex',
                      justifyContent: "center",
                      border: 0,
                      borderRadius: 5,
                      background: 'linear-gradient(107deg, #A97FD5, #5093D6)',
                      padding: 2,
                      marginBottom: 7
                    }}              
                  >
                    <img
                      alt={""}
                      style={{
                        width: 25,
                        height: 25
                      }}
                      src={addToCart}
                    />
                    <p style={{
                      marginLeft: 2,
                      fontWeight: 'bold',
                      fontSize: 9,
                      color: 'white',
                      paddingRight:12 
                    }}>ADD TO CART</p>
                  </div>)
                  :
                  (<div
                    style={{
                      marginLeft: 5,
                      display: 'flex',
                      padding: 2,
                      marginBottom: 7
                    }}              
                  >
                    <img
                      alt={""}
                      style={{
                        width: 25,
                        height: 25
                      }}
                      src={addedToCart}
                    />
                    <p style={{
                      marginLeft: 2,
                      fontWeight: 'bold',
                      fontSize: 9,
                      color: '#2A63DB'
                    }}>ADDED TO CART</p>
                  </div>)}
              </button>   
            </div>
          </div>
        </div>

        <div 
        style={{
          display: "flex",
          flexWrap: 'wrap',
          flexDirection: 'row', 
          justifyContent: "center",
          minWidth: "50%",
          marginTop: 20,
        }}
        >
          <div style={{
            maxWidth: "95%"
          }}>
            <p>{selectedProduct.description}</p>
          </div>
        </div>


        <div style={{
          marginLeft: 6,
          marginTop: 50,
          fontWeight: "bold"
        }}>
          <p>{"MORE TO LOVE:"}</p>
        </div>   


          <div
          style={{
            marginTop: 15,
          }}>
          {items.map((value, index) => {
            return <div 
              key={index}         
              style={{
                marginTop: 15,
                backgroundColor: "white", 
                flex: 0.3,
                display: "flex",
                justifyContent: "center",
                width: "100%"
              }}>
              <ProductBox 
                myText={value.name}
                myPrice={value.price}
                myimgs={value.imgs}
                myDescription={value.description}
                likedContent={likedContent}
                setLikedContent={setLikedContent}
                setSelectedContent={setSelectedContent}
                setSelectedProduct={setSelectedProduct}
                cartObject={cartObject}
                setCartObject={setCartObject}
              ></ProductBox>
            </div>
          })}
        </div>
      </div>
    )
};