import React, {useState} from 'react';
import type {Node} from 'react';

export const ConfirmPaymentPage = ({selectedBank, setSelectedContent, setZotaPoints, selectedAccount, selectedPaymentAlternative}): Node =>{
    const regex = /^[0-9\b]+$/;
    const oneTimeCode = "55679";
    const additionalAmount = 11;
    const [number, onChangeNumber] = useState("");
    const [isError, setIsError] = useState(false);

    function handleChange(event) {
        if(event.target.value === "" || regex.test(event.target.value)){
            onChangeNumber(event.target.value);
        }
    }

    return(        
        <div
        style={{
            backgroundColor: 'white',
            marginTop: 10
        }}>
            <div
                style={{
                display: 'flex',
                justifyContent: 'center'
                }}
            >
                <img
                    style={{
                        alignSelf: 'center',
                        width: selectedBank.bankImageDimensionX,
                        height: selectedBank.bankImageDimensionY,
                    }}
                    alt={""}
                    src={selectedBank.bankImage}
                />
            </div>   
            <div
                style={{
                    marginTop: 20
                }}
            >
                <p style={{
                  alignSelf: 'center',
                  marginTop: 10,
                  textAlign: 'center',
                  fontWeight: "bold"
                }}>{"PLEASE ENTER THIS ONE TIME CODE TO CONFIRM YOUR PAYMENT"}</p>  
            </div>
            <div
                style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <p style={{
                  alignSelf: 'center',
                  marginTop: 10,
                  textAlign: 'center',
                  fontWeight: "bold",
                  color: isError === true ? "#AD0303" : "#000000",
                }}>{"ONE TIME CODE: "}</p>
                <span style={{
                    marginLeft: 10,
                    marginTop: 10,
                    color: isError === true ? "#AD0303" : "#000000",
                }}>{oneTimeCode}</span>
            </div>    
            <div
                style={{
                    marginTop: 10
                }}
            >
                <p style={{
                  alignSelf: 'center',
                  marginTop: 20,
                  textAlign: 'center',
                  fontWeight: "bold",
                  color: isError === true ? "#AD0303" : "#000000",
                }}>{"ENTER ONE TIME CODE:"}</p>  
            </div>  

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <input
                    style={{
                        height: 40,
                        margin: 12,
                        borderWidth: 1,
                        minWidth: '20%',
                        alignSelf: 'center',
                        fontSize: 24,
                        textAlign: "center"
                    }}               
                    type="password"
                    pattern="[0-9]*" 
                    value={number} 
                    onChange={handleChange} 
                    maxLength={5}
                />

            </div>
            
            <div 
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        maxWidth: "50%",
                        alignSelf: 'center',
                        textAlign: 'center',                    
                    }}
                >
                    <span
                        style={{
                            fontWeight: "bold",
                            color: isError === true ? "#AD0303" : "#000000",
                        }}
                    >
                        {isError === true 
                        ? "* The code is incorrect. Please, check and try again!" 
                        : ""}
                    </span>
                </div>
            </div>
            

            <div
                style={{
                    marginTop: 10
                }}
            >
                <p style={{
                  alignSelf: 'center',
                  marginTop: 30,
                  textAlign: 'center',
                  fontWeight: "bold"
                }}>{"ACCOUNT:"}</p>  
            </div>             
            <div>
                <p style={{
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: 20,
                  fontWeight: "bold"
                }}>{selectedAccount.accountNumber}</p>  
            </div> 


            <div
                style={{
                    marginTop: 20,
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <p style={{
                  alignSelf: 'center',
                  marginTop: 15,
                  textAlign: 'center',
                  fontWeight: "bold"
                }}>{"AMOUNT: "}</p>
                <span style={{
                    marginLeft: 10,
                    alignSelf: 'center',
                    textAlign: 'center'                  
                }}>{additionalAmount + " GBP"}</span>  
            </div>  

            <div
                style={{
                backgroundColor: 'white',
                display: "flex",
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'center',
            }}>
                <div 
                    style={{
                    marginTop: 7,
                    backgroundColor: "white", 
                    flexBasis: '50%',
                    justifyContent: 'center',
                    alignContent: 'center',
                    display: 'flex'
                }}>                
                    <button
                        style={{
                            width: 158,
                            height: 60,
                            paddingTop: 12,
                            paddingBottom: 12,
                            backgroundColor: "white",
                            border: 0,
                            borderRadius: 15,
                            background: 'linear-gradient(107deg, #A97FD5, #5093D6)',
                        }}
                        onClick={() => {
                            if(oneTimeCode === number){
                                setIsError(false);
                                setZotaPoints(current => current + selectedPaymentAlternative);
                                setSelectedContent("SuccessPaymentPage");
                            }else{
                                setIsError(true);
                            }
                        }}  
                    >
                        <p style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: 11
                        }}>CONFIRM PAYMENT</p>   
                    </button> 
                </div>

            </div>
        </div>           
    )
}