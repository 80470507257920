import React, {useState} from 'react';
import type {Node} from 'react';
import zotaLogo from "../assets/zotaLogo.svg";

export const SpecialPaymentPage = ({setSelectedContent, setSelectedPaymentAlternative, cartObject, zotaPoints}): Node =>{
    const regex = /^[0-9\b]+$/;


    const totalPrice = (cartObject.length === 1 ? 
    (cartObject[0].price*cartObject[0].quantity) 
    : 
    ((cartObject.length > 0 ? (
        cartObject.map(function(obj) { return obj.price*obj.quantity; }).reduce((accumulator, currentValue) => accumulator + currentValue)
    ) : (
        0
    )))) - zotaPoints;


    const required = totalPrice.toString();

    const [number, onChangeNumber] = useState(totalPrice);


    function handleChange(event) {
        if(event.target.value !== "" && regex.test(event.target.value)){
            onChangeNumber(event.target.value);
        }
    }

    return(        
      <div
        style={{
          backgroundColor: 'white',
        }}>
            <div
                style={{
                    marginTop: 8,
                    display: "flex",
                    flexWrap: 'wrap',                    
                    flexDirection: 'row',
                    justifyContent: "center",
                }}              
            >
                <p style={{
                    fontWeight: "bold"
                }}>{"ADD CUSTOM AMOUNT OF POINTS:" }</p>  
            </div>           

            <div
                style={{
                    marginTop: 20,
                    display: "flex",
                    flexWrap: 'wrap',
                    justifyContent: "center",
                }}              
                >
                <div
                    style={{
                        display: "flex",
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt={""}
                        style={{
                            alignSelf: 'center',
                            width: 16,
                            height: 20
                        }}
                        src={zotaLogo}
                    />
                    <span style={{
                        textAlignVertical: 'center',
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: 6
                    }}>{"ZotaPoints"}</span>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >

                        <input
                            style={{
                                height: 40,
                                margin: 12,
                                borderTop: "0px",
                                borderLeft: "0px",
                                borderRight: "0px",
                                borderBottom: "2px solid #dddddd",
                                minWidth: '20%',
                                fontSize: 24,
                                textAlign: "center",
                                color: '#2A63DB',
                                fontWeight: "bold",
                            }}                
                            type="text" 
                            pattern="[0-9]*" 
                            value={number} 
                            onChange={handleChange} 
                            maxLength={5}
                        />
                    </div>                   
                </div>
            </div> 

            <div
                style={{
                backgroundColor: 'white',
                display: "flex",
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'center'
            }}>
                <div 
                    style={{
                    marginTop: 7,
                    height: 100,
                    backgroundColor: "white", 
                    display: "flex",
                    flexBasis: '50%',
                    justifyContent: 'center',
                }}>                
                    <button
                        style={{
                            width: '70%',
                            paddingTop: 12,
                            paddingBottom: 12,
                            alignSelf: 'center',
                            backgroundColor: "white",
                            border: 0,
                            borderRadius: 15,
                            background: 'linear-gradient(107deg, #A97FD5, #5093D6)'
                        }}
                        onClick={() => {
                            if(parseInt(number) >= parseInt(required)){
                                setSelectedPaymentAlternative(parseInt(number));
                                setSelectedContent("BankPage");
                            }
                        }}  
                    >
                        <p style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: 11
                        }}>CHECKOUT</p>        
                    </button> 
                </div>          
            </div>


            <div
            style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <span>{"You have "}</span>
                <img
                    alt={""}
                    style={{
                        marginLeft: 6,
                        width: 16,
                        height: 20
                    }}
                    src={zotaLogo}
                />
                <span style={{
                    marginLeft: 6,
                    color: "#2A63DB",
                    fontWeight: "bold",
                }}>{number}</span>
                <span>{" / " + required}</span>
                <span style={{
                    textAlignVertical: 'center',
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: 6
                }}>{"ZotaPoints"}</span>
            </div>               
      </div>
    )
}