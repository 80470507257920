import React from 'react';
import type {Node} from 'react';
import zotaLogo from "../assets/zotaLogo.svg";
import plusButton from "../assets/plusSVG.svg"
import minusButton from "../assets/minusSVG.svg"
import cancelButton from "../assets/cancelSVG.svg";

const ProductBox = ({myText, myPrice, myQuantity, myimg, myIdx, setCartObject}): Node =>{
    return(        
      <div
        style={{
          backgroundColor: 'white',
            marginTop: 30,
            display: "flex",
        }}>
            <div style={{
                flexBasis: '20%'
            }}>
                <img
                    style={{    
                        width: 98,
                        height: 164
                    }}
                    alt={""}
                    src={myimg}
                /> 
            </div>
            <div style={{
                marginLeft: 30,
                flexBasis: '70%'
            }}>
                <div> 
                    <p
                        style={{
                            fontSize: 16,
                            fontWeight: "bold"
                        }}>
                        {myText.toUpperCase()}
                    </p> 
                </div>
                <div style={{
                    marginTop: 30,
                    display: "flex",
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                }}> 
                    <div
                        style={{
                            maxWidth: "40%"
                        }}
                    >
                        <p
                            style={{
                                alignContent: 'center'
                            }}>
                            {"Quantity: " + myQuantity}
                        </p>  
                    </div>
                    <div style={{
                        display: "flex",
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        maxWidth: "70%",
                        marginTop: 10
                    }}> 
                        <div
                            style={{
                                marginLeft: 10,
                                marginBottom: 5,
                            }}
                        >
                            <img
                                style={{
                                    width: 26,
                                    height: 26,
                                }}
                                alt={""}
                                src={plusButton}
                                onClick={() => {
                                    // Increase Quantity
                                    setCartObject((c) => {
                                        const newArray = [...c];
                                        if(newArray[myIdx].quantity < 9){
                                            newArray[myIdx].quantity += 1;
                                        }
                                        return newArray;
                                    })
                                }}
                            />               
                        </div>

                        <div
                            style={{
                                marginLeft: 7,
                                marginBottom: 5,
                            }}
                        >       
                            <img
                                style={{
                                    width: 26,
                                    height: 26,
                                }}
                                alt={""}
                                src={minusButton}
                                onClick={() => {
                                    // Decrease Quantity
                                    setCartObject((current) => {
                                        const newArray = [...current];
                                        if(newArray[myIdx].quantity > 0){
                                            newArray[myIdx].quantity -= 1;
                                        }
                                        return newArray;
                                    })                                
                                }}                                   
                            />               
                        </div>
                            
                        <div
                            style={{
                                marginLeft: 7,
                                marginBottom: 5,
                            }}
                        >                       
                            <img
                                style={{
                                    width: 26,
                                    height: 26,
                                }}
                                alt={""}
                                src={cancelButton}
                                onClick={() => {
                                    // Remove from Cart
                                    setCartObject((current) => current.filter((element) => element.name !== myText))
                                }}
                            />               
                        </div>
                    </div>                                       
                </div> 
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 10,
                    }}
                >
                    <div>
                        <img
                            style={{
                                width: 16,
                                height: 20
                            }}
                            alt={""}
                            src={zotaLogo}
                        />
                    </div>
                    <div>
                        <span style={{
                            fontWeight: "bold",
                            color: "#2A63DB",
                            marginLeft: 5,
                        }}>{myPrice*myQuantity}</span>
                        <span style={{
                            marginBottom: 7,
                        }}>{' ZotaPoints'}</span> 
                    </div>
                </div>
     
            </div>
      </div>
    )
  }

  
export const CheckoutPage = ({cartObject, setCartObject, zotaPoints, setZotaPoints, setSelectedContent}): Node =>{
    const newDate = new Date(new Date().getTime()+(5*24*60*60*1000));
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = '.';
    const currentDate =  `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`


    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
            return "Android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
    
        return "unknown";
    }


    const totalPrice = cartObject.length === 1 ? 
        (cartObject[0].price*cartObject[0].quantity) 
        : 
        ((cartObject.length > 0 ? (
            cartObject.map(function(obj) { return obj.price*obj.quantity; }).reduce((accumulator, currentValue) => accumulator + currentValue)
        ) : (
            0
        )));
        
    return(        
        <div
        style={{
            backgroundColor: 'white',
        }}>
            <p style={{
                fontSize: 22,
                textAlign: 'center',
                fontWeight: "bold"
            }}>{cartObject.length === 0 ? "YOUR CART IS EMPTY" :"YOUR ORDER WILL BE DELIVERED BEFORE " + currentDate}</p>    
            {cartObject.map((value, index) => {
                if(value !== undefined){
                    return (<div 
                        key={index}         
                        style={{
                            marginTop: 10,
                            backgroundColor: "white", 
                            display: "flex",
                            justifyContent: "center"
                        }}>
                        <ProductBox 
                            myText={value.name}
                            myPrice={value.price}
                            myQuantity={value.quantity}
                            myimg={value.imgs[0]}
                            myIdx={index}
                            setCartObject={setCartObject}
                        ></ProductBox>
                    </div>)
                }else{
                    return (<div></div>)
                }
            })}   
            <div
                style={{
                    marginTop: 20,
                    display: "flex",
                    flexWrap: 'wrap',
                    justifyContent: "center",
                }}              
              >
                <div
                    style={{
                        display: "flex",
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        justifyContent: "center",
                    }}
                >
                    <span style={{
                        fontWeight: "bold"
                    }}>{"TOTAL: "}</span>
                    <div
                        style={{
                            marginLeft: 15
                        }}
                    >
                        <img
                            style={{
                                width: 16,
                                height: 20
                            }}
                            alt={""}
                            src={zotaLogo}
                        />
                    </div>
                    <span style={{
                        marginLeft: 6
                    }}>{totalPrice + " ZotaPoints"}</span>
                </div>
            </div>  

            <div
                style={{
                    marginTop: 30,
                    display: "flex",
                    flexWrap: 'wrap',
                    justifyContent: "center",
                }}              
            >
                <div
                    style={{
                        display: "flex",
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        justifyContent: "center",
                    }}
                >
                    <span>{"You have "}</span>
                    <div
                        style={{
                            marginLeft: 15
                        }}
                    >
                        <img
                            style={{
                                width: 16,
                                height: 20
                            }}
                            alt={""}
                            src={zotaLogo}
                        />
                    </div>
                    <span style={{
                        fontWeight: "bold",
                        color: "#2A63DB",
                        marginLeft: 6
                    }}>{zotaPoints}</span>
                    <span style={{
                        marginLeft: 6
                    }}>{" / " + totalPrice + " ZotaPoints"}</span>
                </div>
            </div>
            {totalPrice > zotaPoints ? 
            (<div
                style={{
                    marginTop: 8,
                    display: "flex",
                    flexWrap: 'wrap',                    
                    flexDirection: 'row',
                    justifyContent: "center",
                }}              
            >
                <p>{"You can add more points during checkout"}</p>
            </div>) 
            : (<div></div>)}


            <div
                style={{
                backgroundColor: 'white',
                display: "flex",
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'center'
            }}>
                <div 
                    style={{
                    marginTop: 7,
                    height: 100,
                    backgroundColor: "white", 
                    flexBasis: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                }}>                
                    <button
                        style={{
                            width: 158,
                            height: 60,
                            paddingTop: 12,
                            paddingBottom: 12,
                            alignSelf: 'center',
                            border: "2px solid #dddddd",
                            borderRadius: 15,
                            boxShadow: "2px 2px #dddddd",
                            backgroundColor: "white",
                        }}
                        onClick={() => setSelectedContent('MainPage')}  
                    >
                        <p style={{
                            color: '#2A63DB',
                            fontWeight: 'bold',
                            fontSize: 11
                        }}>CONTINUE SHOPPING</p>           
                    </button> 
                </div>

                <div 
                    style={{
                    marginTop: 7,
                    height: 100,
                    backgroundColor: "white", 
                    flexBasis: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center'
                }}>                
                    <button
                        style={{
                            width: 158,
                            height: 60,
                            paddingTop: 12,
                            paddingBottom: 12,
                            alignSelf: 'center',
                            backgroundColor: "white",
                            border: 0,
                            borderRadius: 15,
                            background: 'linear-gradient(107deg, #A97FD5, #5093D6)',
                        }}
                        onClick={() => {
                            if(cartObject.length){
                                if(zotaPoints >= totalPrice){
                                    setZotaPoints(zotaPoints - totalPrice);
                                    setSelectedContent('SuccessBuyPage');
                                }else{
                                    setSelectedContent('PaymentPage');
                                }
                            }
                        }}  
                    >
                        <p style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: 11
                        }}>CHECKOUT</p>         
                    </button> 
                </div>

            </div>
        </div>
    )
}
  