import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {StartPage} from './components/StartPage';
import {MainPage} from './components/MainPage';
import {ProductPage} from './components/ProductPage';
import {CheckoutPage} from './components/CheckoutPage';
import {PaymentPage} from './components/PaymentPage';
import {SpecialPaymentPage} from './components/SpecialPaymentPage';
import {BankPage} from './components/BankPage';
import {AccountPage} from './components/AccountPage';
import {ConfirmPaymentPage} from './components/ConfirmPaymentPage';
import {SuccessBuyPage} from './components/SuccessBuyPage';
import {SuccessPaymentPage} from './components/SuccessPaymentPage';
import {BackButton} from './components/BackButton';
import imgHeader from './assets/Deportivo_Alaves_logo_(2020).svg';
import imgLogo from './assets/logo-8@2x.png';
import imgBlank from './assets/imgBlank.png';
import img1 from './assets/1@2x.png';
import img2 from './assets/2@2x.png';
import img3 from './assets/3@2x.png';
import img4 from './assets/4@2x.png';
import img5 from './assets/5@2x.png';
import img6 from './assets/6@2x.png';
import img7 from './assets/7@2x.png';
import img8 from './assets/8@2x.png';
import img9 from './assets/9@2x.png';
import img10 from './assets/10@2x.png';
import img11 from './assets/11@2x.png';
import img12 from './assets/12@2x.png';
import img13 from './assets/13@2x.png';
import img14 from './assets/14@2x.png';
import img15 from './assets/15@2x.png';
import img16 from './assets/16@2x.png';
import img17 from './assets/17@2x.png';
import imgShirtFront from './assets/shirt-front.png';
import imgShirtBack from './assets/shirt-back.png';
import checkoutImg0 from './assets/empty-cart.svg';
import checkoutImg1 from './assets/added-1.svg';
import checkoutImg2 from './assets/added-2.svg';
import checkoutImg3 from './assets/added-3.svg';
import checkoutImg4 from './assets/added-4.svg';
import checkoutImg5 from './assets/added-5.svg';
import zotaWhiteLogo from "./assets/logo@2x.png";

/*
// Add country field UK above the Logos of the banks;
// Saving & Checking Account Page - add hover event in gradient color.
-> Fix fonts - color, bold, capitalization as per design
-> Add the white shadow box for each product instead of the frame - as per design
-> Fix navigational bar - the distance of cart and menu icon as per design
-> Add "more to love" section to each Product page as per design - with the remaining product items
-> Make images smaller for the main page with the products - the user should be able to see part of the next product in order to know there is more down.
-> Remove button "To the product", make product image and title clickable - click leads to the product page
-> Add all bank logos as per design.
-> After the "Payment successful" animation user goes to the checkout screen with updated Zotapoints. Users can continue adding products to the cart. The text "You can add more points during checkout is removed.
*/

const App: () => Node = () => {
  const toCheckoutImage = [
    checkoutImg0,
    checkoutImg1,
    checkoutImg2,
    checkoutImg3,
    checkoutImg4,
    checkoutImg5,
  ];

  const [zotaPoints, setZotaPoints] = useState(1050);
  const [selectedBank, setSelectedBank] = useState({});
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedPaymentAlternative, setSelectedPaymentAlternative] = useState(-1);
  const [selectedProduct, setSelectedProduct] = useState({
    name: "",
    price: 0,
    imgs: [],
    quantity: 1,
    description: ""
  });
  const elements = [
    {
      name: 'Official Shirt Season 21/22',
      price: 1200,
      imgs: [
        imgShirtFront,
        imgShirtBack,
        imgShirtFront,
        imgShirtBack
      ],
      quantity: 1,
      description: "Official Shirt Season 21/22 Description"
    },     
    {
      name: 'Official Helmet Season 21/22',
      price: 1200,
      imgs: [
        img1,
        img2,
        img3,
        img4,
        img5
      ],
      quantity: 1,
      description: "Official Helmet Season 21/22 Description"
    }, 
    {
      name: 'Official Scarf Season 21/22',
      price: 1200,
      imgs: [
        img6,
        img7,
        img8,
        img9
      ],
      quantity: 1,
      description: "Official Scarf Season 21/22 Description"
    }, 
    {
      name: 'Official Boots Season 21/22',
      price: 1200,
      imgs: [
        img10,
        img11,
        img12,
        img13,
        img14
      ],
      quantity: 1,
      description: "Official Boots Season 21/22 Description"
    }, 
    {
      name: 'Official Case Season 21/22',
      price: 1200,
      imgs: [
        img15,
        img16,
        img17
      ],
      quantity: 1,
      description: "Official Case Season 21/22 Description"
    },    
  ];

  const [cartObject, setCartObject] = useState([]);
  const [selectedContent, setSelectedContent] = useState("StartPage");
  const [likedContent, setLikedContent] = useState([]);
  const [contentObject, setContentObject] = useState({
    StartPage: <StartPage
      setSelectedContent={setSelectedContent}
    />,
    MainPage: <MainPage
      elements={elements}
      setSelectedContent={setSelectedContent}
      setSelectedProduct={setSelectedProduct}
      cartObject={cartObject}
      setCartObject={setCartObject}
      likedContent={likedContent}
      setLikedContent={setLikedContent}
    />,
    ProductPage: <ProductPage
      elements={elements}
      selectedProduct={selectedProduct}
      setSelectedContent={setSelectedContent}
      setSelectedProduct={setSelectedProduct}
      cartObject={cartObject}
      setCartObject={setCartObject}
      likedContent={likedContent}
      setLikedContent={setLikedContent}
    />,
    CheckoutPage: <CheckoutPage
      cartObject={cartObject}
      setCartObject={setCartObject}
      zotaPoints={zotaPoints}
      setZotaPoints={setZotaPoints}
      setSelectedContent={setSelectedContent}
    />,
    PaymentPage: <PaymentPage
      cartObject={cartObject}
      setSelectedContent={setSelectedContent}
      zotaPoints={zotaPoints}
      selectedPaymentAlternative={selectedPaymentAlternative}
      setSelectedPaymentAlternative={setSelectedPaymentAlternative}
    />,
    SpecialPaymentPage: <SpecialPaymentPage
      setSelectedContent={setSelectedContent}
      setSelectedPaymentAlternative={setSelectedPaymentAlternative}
      cartObject={cartObject}
      zotaPoints={zotaPoints}
    />,
    BankPage: <BankPage
      setSelectedContent={setSelectedContent}
      setSelectedBank={setSelectedBank}
    />,
    AccountPage: <AccountPage
      selectedBank={selectedBank}
      setSelectedContent={setSelectedContent}
      setSelectedAccount={setSelectedAccount}
    />,
    ConfirmPaymentPage: <ConfirmPaymentPage
      selectedBank={selectedBank}
      setSelectedContent={setSelectedContent}
      setZotaPoints={setZotaPoints}
      selectedAccount={selectedAccount}
      selectedPaymentAlternative={selectedPaymentAlternative}
    />,
    SuccessPaymentPage: <SuccessPaymentPage
      setSelectedContent={setSelectedContent}
      setCartObject={setCartObject}
    />,
    SuccessBuyPage: <SuccessBuyPage
      setSelectedContent={setSelectedContent}
      setCartObject={setCartObject}
    />
  });


  useEffect(()=>{
    setContentObject(()=>{
      const newObject = {...contentObject};
      newObject.ConfirmPaymentPage = <ConfirmPaymentPage
        selectedBank={selectedBank}
        setSelectedContent={setSelectedContent}
        setZotaPoints={setZotaPoints}
        selectedAccount={selectedAccount}
        selectedPaymentAlternative={selectedPaymentAlternative}
      />
      return newObject;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedAccount]);

  useEffect(()=>{
    setContentObject(()=>{
      const newObject = {...contentObject};
      newObject.ProductPage = <ProductPage
        elements={elements}
        selectedProduct={selectedProduct}
        setSelectedContent={setSelectedContent}
        setSelectedProduct={setSelectedProduct}
        cartObject={cartObject}
        setCartObject={setCartObject}
        likedContent={likedContent}
        setLikedContent={setLikedContent}
      />;
      return newObject;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedProduct]);

  useEffect(()=>{
    setContentObject(()=>{
      const newObject = {...contentObject};
      newObject.MainPage = <MainPage
        elements={elements}
        setSelectedContent={setSelectedContent}
        setSelectedProduct={setSelectedProduct}
        cartObject={cartObject}
        setCartObject={setCartObject}
        likedContent={likedContent}
        setLikedContent={setLikedContent}
      />;
      newObject.ProductPage = <ProductPage
        elements={elements}
        selectedProduct={selectedProduct}
        setSelectedContent={setSelectedContent}
        setSelectedProduct={setSelectedProduct}
        cartObject={cartObject}
        setCartObject={setCartObject}
        likedContent={likedContent}
        setLikedContent={setLikedContent}
      />;
      newObject.CheckoutPage = <CheckoutPage
        cartObject={cartObject}
        setCartObject={setCartObject}
        zotaPoints={zotaPoints}
        setZotaPoints={setZotaPoints}
        setSelectedContent={setSelectedContent}
      />
      newObject.PaymentPage = <PaymentPage
        cartObject={cartObject}
        setSelectedContent={setSelectedContent}
        zotaPoints={zotaPoints}
        selectedPaymentAlternative={selectedPaymentAlternative}
        setSelectedPaymentAlternative={setSelectedPaymentAlternative}
      />
      newObject.SpecialPaymentPage = <SpecialPaymentPage
        setSelectedContent={setSelectedContent}
        setSelectedPaymentAlternative={setSelectedPaymentAlternative}
        cartObject={cartObject}
        zotaPoints={zotaPoints}
      />
      return newObject;
    });
    setSelectedPaymentAlternative(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cartObject]);

  useEffect(()=>{
    setContentObject(()=>{
      const newObject = {...contentObject};
      newObject.PaymentPage = <PaymentPage
        cartObject={cartObject}
        setSelectedContent={setSelectedContent}
        zotaPoints={zotaPoints}
        selectedPaymentAlternative={selectedPaymentAlternative}
        setSelectedPaymentAlternative={setSelectedPaymentAlternative}
      />
      return newObject;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedPaymentAlternative]);

  useEffect(()=>{
    setContentObject(()=>{
      const newObject = {...contentObject};
      newObject.AccountPage = <AccountPage
        selectedBank={selectedBank}
        setSelectedContent={setSelectedContent}
        setSelectedAccount={setSelectedAccount}
      />
      newObject.ConfirmPaymentPage = <ConfirmPaymentPage
        selectedBank={selectedBank}
        setSelectedContent={setSelectedContent}
        setZotaPoints={setZotaPoints}
        selectedAccount={selectedAccount}
        selectedPaymentAlternative={selectedPaymentAlternative}
      />
      return newObject;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedBank]);
  
  useEffect(()=>{
    setContentObject(()=>{
      const newObject = {...contentObject};
      newObject.MainPage = <MainPage
        elements={elements}
        setSelectedContent={setSelectedContent}
        setSelectedProduct={setSelectedProduct}
        cartObject={cartObject}
        setCartObject={setCartObject}
        likedContent={likedContent}
        setLikedContent={setLikedContent}
      />;
      newObject.ProductPage = <ProductPage
        elements={elements}
        selectedProduct={selectedProduct}
        setSelectedContent={setSelectedContent}
        setSelectedProduct={setSelectedProduct}
        cartObject={cartObject}
        setCartObject={setCartObject}
        likedContent={likedContent}
        setLikedContent={setLikedContent}
      />;
      return newObject;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[likedContent]);

    useEffect(()=>{
      setContentObject(()=>{
        const newObject = {...contentObject};
        newObject.CheckoutPage = <CheckoutPage
          cartObject={cartObject}
          setCartObject={setCartObject}
          zotaPoints={zotaPoints}
          setZotaPoints={setZotaPoints}
          setSelectedContent={setSelectedContent}
        />
        newObject.PaymentPage = <PaymentPage
          cartObject={cartObject}
          setSelectedContent={setSelectedContent}
          zotaPoints={zotaPoints}
          selectedPaymentAlternative={selectedPaymentAlternative}
          setSelectedPaymentAlternative={setSelectedPaymentAlternative}
        />
        newObject.SpecialPaymentPage = <SpecialPaymentPage
          setSelectedContent={setSelectedContent}
          setSelectedPaymentAlternative={setSelectedPaymentAlternative}
          cartObject={cartObject}
          zotaPoints={zotaPoints}
        />
        return newObject;
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[zotaPoints]);

  const backgroundStyle = {
    backgroundColor: "white",
    width: "99%",
    margin: "0px auto",
    border: "2px solid #dddddd",
    maxWidth: 450,
    minHeight: "99%"
  };

  return (
    <div style={backgroundStyle}>
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto"
        }
      }>
        {selectedContent === "StartPage" ? (
          <div></div>
        ) : (
          <div
            style={{
              width: "100%",
              alignSelf: "center",
              backgroundColor: "white",
              marginTop: 15,
              marginLeft: "auto",
              marginRight: "auto",
              height: 75,
              display: "flex",
              justifyContent: "center",
              borderBottom: "2px solid #dddddd"
            }}>
              <div
                style={{
                  marginTop: 10,
                  flexBasis: "33%",
                }}
              >
                <button
                  style={{
                    border: 0,
                    backgroundColor: "white",
                  }}          
                  onClick={() => {}}   
                >
                  <img
                    style={{
                      marginTop: 20,
                      width: 22,
                      height: 17
                    }}
                    alt={""}
                    src={imgBlank}
                  />  
                </button>  
              </div>
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  flexBasis: "33%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <img
                    style={{
                      border: 0,
                      backgroundColor: "white",
                      width: 104,
                      height: 41
                    }}   
                    alt={""}       
                    src={imgLogo}
                    onClick={() => setSelectedContent("MainPage")}
                />
              </div>
              <div
                style={{
                  marginTop: 6,
                  flexBasis: "33%",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <button
                  style={{
                    border: 0,
                    backgroundColor: "white",
                  }}          
                  onClick={() => setSelectedContent("CheckoutPage")}   
                >
                  <img
                    style={{
                      width: 25,
                      height: 25
                    }}
                    alt={""} 
                    src={toCheckoutImage[cartObject.length]}
                  />  
                </button> 
              </div>
          </div>
        )}

        {(selectedContent === "StartPage" || selectedContent === "SuccessBuyPage") ? (
          <div></div>
        ) : (
          <div>
            {selectedContent !== "MainPage" ? (
                <BackButton
                  selectedContent={selectedContent}
                  setSelectedContent={setSelectedContent}
                />
            ) : (
              <div
                style={{
                  minHeight: 80,
                  width: "100%",
                  background: 'linear-gradient(107deg, #A97FD5, #5093D6)',
                  alignSelf: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      marginTop: 10
                    }}
                  >
                    <img
                      style={{
                        width: 53,
                        height: 53,
                      }}
                      alt={""} 
                      src={imgHeader}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: 8,
                      marginTop: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <span style={{
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: 18
                    }}>PRODUCT MERCHANDISE 21/22</span>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <img
                        alt={""}
                        src={zotaWhiteLogo}
                        style={{
                          width: 16,
                          height: 20
                        }}
                      />
                      <span style={{
                        color: 'white',
                        fontSize: 13,
                        marginLeft: 5
                      }}>Get products now with ZotaPoints</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        
        <div
        id={"CONTENT OBJECT"}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}>
          {contentObject[selectedContent]}
        </div>
      </div>
    </div>
  );
};

export default App;