import React from 'react';
import type {Node} from 'react';

import "../App.css";

export const AccountPage = ({selectedBank, setSelectedContent, setSelectedAccount}): Node =>{
  const accountList = [
    {
      accountType: "Savings Account",
      accountNumber: "2233 4455 111",
      accountHolder: "Thomas Edison"
    },
    {
      accountType: "Checking Account",
      accountNumber: "2233 4455 222",
      accountHolder: "Thomas Edison"
    }
  ];

  return(        
    <div
      style={{
        backgroundColor: 'white',
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <img
          style={{
            alignSelf: 'center',
            width: selectedBank.bankImageDimensionX,
            height: selectedBank.bankImageDimensionY,
          }}
          alt={""}
          src={selectedBank.bankImage}
        />
      </div>
      
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <p style={{
          maxWidth: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
          fontSize: 14,
          fontWeight: "bold"
        }}>{"SELECT THE BANK ACCOUNT FROM WHICH THE MONEY SHOULD BE SENT:"}</p>
      </div>
      {accountList.map((value, index) => {
          return <div
            key={index}   
            style={{
              alignSelf: 'center',
              marginTop: 20,
              minWidth: '80%',
              alignContent: 'center',
              border: "2px solid #dddddd",
              borderRadius: 10,
              display: "flex",
              justifyContent: 'center'
            }}
            >
              <button
                style={{
                  border: 0,
                  width: "100%",
                  height: "100%"
                }}
                className={"accountButton"}  
                onClick={() => {
                  setSelectedAccount(value);
                  setSelectedContent("ConfirmPaymentPage");
                }}
              >
                <p style={{
                  alignSelf: 'center',
                  marginTop: 10,
                  fontWeight: "bold"
                }}>{value.accountType.toUpperCase()}</p>
                <p style={{
                  alignSelf: 'center',
                  fontSize: 20,
                  marginTop: 15,
                }}>{value.accountNumber}</p>
                <p style={{
                  alignSelf: 'center',
                  fontSize: 15,
                  fontWeight: "bold"
                }}>{value.accountHolder.toUpperCase()}</p>
              </button>
          </div>
        })}             
    </div>
  )
}