import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import successAnimation1 from "../assets/Group 387.svg";
import successAnimation2 from "../assets/Group 388.svg";
import successAnimation3 from "../assets/Group 389.svg";
import successAnimation4 from "../assets/Group 390.svg";
import successAnimation5 from "../assets/Group 391.svg";
import successAnimation6 from "../assets/Group 392.svg";
import successAnimation7 from "../assets/Group 393.svg";


export const SuccessBuyPage = ({setSelectedContent, setCartObject}): Node =>{
    const [animationCount, setAnimationCount] = useState(0);
    const animationFrames = [
      successAnimation1,
      successAnimation2,
      successAnimation3,
      successAnimation4,
      successAnimation5,
      successAnimation6,
      successAnimation7,
    ];

    useEffect(() => {
      setTimeout(() => {     
        if(animationCount + 1 < animationFrames.length){
          setAnimationCount(animationCount + 1);
        }
      }, 500)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationCount]);

    return(        
      <div
        style={{
          backgroundColor: 'white',
        }}>
          <div
            style={{
              maxWidth: window.innerHeight > window.innerWidth ? "100%" : 450,
              overflow: window.innerHeight > window.innerWidth ? 'visible' : 'hidden',
              display: 'flex',
              justifyContent: 'center',
              minWidth: 450,
            }}
          >
              <img
                alt={""}
                style={{
                  alignSelf: 'center',
                  width: animationCount + 1 === animationFrames.length ? 401 : 780,
                  height: animationCount + 1 === animationFrames.length ? 401 : 780,
                }}
                src={animationFrames[animationCount]}
              />    
          </div>
 
        {animationCount + 1 === animationFrames.length ? (
                <div 
                style={{
                marginTop: 7,
                backgroundColor: "white", 
                flexBasis: '50%',
                justifyContent: 'center',
                alignContent: 'center',
                display: "flex"
            }}>                
                <button
                        style={{
                          width: 158,
                          height: 60,
                          paddingTop: 12,
                          paddingBottom: 12,
                          backgroundColor: "white",
                          border: 0,
                          borderRadius: 15,
                          background: 'linear-gradient(107deg, #A97FD5, #5093D6)',
                      }}
                    onClick={() => {
                      setCartObject([]);
                      setSelectedContent("MainPage");
                    }}
                >
                        <p style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: 9
                        }}>GO BACK TO HOME PAGE</p>       
                </button> 
            </div>          
        ) : (<div></div>)}
      </div>
    )
}