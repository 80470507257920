import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import intro1 from '../assets/intro1.png';
import intro2 from '../assets/intro2.png';
import intro3 from '../assets/intro3.png';
import intro4 from '../assets/intro4.png';
import intro5 from '../assets/intro5.png';
import intro6 from '../assets/intro6.png';
import logo from '../assets/Deportivo_Alaves_logo_(2020).svg';
import zotaWhiteLogo from "../assets/logoWhite.svg"
import zotaLogo from '../assets/logo (2).svg';
import background from '../assets/Path 1856.svg';
import whiteElipse from "../assets/Ellipse 1.svg";

export const StartPage = ({setSelectedContent}): Node =>{
    const [animationCount, setAnimationCount] = useState(0);

    const [eclipseSize, setEclipseSize] = useState({w:259, h:259, t: 300});

    const animationFrames = [
      intro1,
      intro2,
      intro3,
      intro4,
      intro5,
      intro6,
    ];

    useEffect(() => {
      setTimeout(() => {     
        if(animationCount + 1 < animationFrames.length){
          if(animationCount === 0){
            setEclipseSize({w: 359, h: 359, t: 250})
          }else if(animationCount === 1){
            setEclipseSize({w: 659, h: 659, t: 100})
          }else if(animationCount === 2){
            setEclipseSize({w: 359, h: 359, t: 250})
          }else if(animationCount === 3){
            setEclipseSize({w: 259, h: 259, t: 300})
          }else if(animationCount === 4){
            setEclipseSize({w: 659, h: 659, t: 100})
          }
          setAnimationCount(animationCount + 1);
          
        }else{
            setSelectedContent("MainPage");            
        }
      }, 500)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationCount]);

    return(        
      <div
        style={{
          minWidth: "100%",
          minHeight: "100%",
          background: 'linear-gradient(107deg, #A97FD5, #5093D6)',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
          <img
            alt={""}
            style={{
              width: '100%',
              height: '100%',
            }}
            src={background}
          />   

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              top: eclipseSize.t,
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: window.innerHeight > window.innerWidth ? "100%" : 450,
              overflow: window.innerHeight > window.innerWidth ? 'visible' : 'hidden'
            }}
          >
            <img
              style={{
                width: eclipseSize.w,
                height: eclipseSize.h,
              }}
              alt={""}
              src={whiteElipse}
            />  
          </div>


          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              top: 150,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <img
              alt={""}
              src={(animationCount === 2 || animationCount === 4 || animationCount === 5) ? zotaLogo : zotaWhiteLogo}
            />  
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              top: 300,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <img
              style={{
                width: 259,
                height: 259
              }}
              alt={""}
              src={logo}
            />  
          </div>
      </div>
    )
}