import React from 'react';
import type {Node} from 'react';
import backButton from "../assets/back@2x.png";

export const BackButton = ({selectedContent,setSelectedContent}): Node =>{
    return(
        <button
            style={{
                display: "flex",
                alignContent: 'center',
                border: 0,
                backgroundColor: "white",
            }}          
            onClick={() => {
                switch(selectedContent){
                    case "MainPage":
                        setSelectedContent("MainPage");
                        break;
                    case "ProductPage":
                        setSelectedContent("MainPage");
                        break;
                    case "CheckoutPage":
                        setSelectedContent("MainPage");
                        break;                  
                    case "PaymentPage":
                        setSelectedContent("CheckoutPage");
                        break;          
                    case "SpecialPaymentPage":
                        setSelectedContent("PaymentPage");
                        break;         
                    case "BankPage":
                        setSelectedContent("PaymentPage");
                        break;                 
                    case "AccountPage":
                        setSelectedContent("BankPage");
                        break;      
                    case "ConfirmPaymentPage":
                        setSelectedContent("AccountPage");
                        break;                                           
                    case "SuccessPaymentPage":
                        setSelectedContent("MainPage");
                        break;    
                    case "SuccessBuyPage":
                        setSelectedContent("MainPage");
                        break;   
                    default:
                        break;              
                }
            }} 
        >
            <img
                style={{
                    width: 10,
                    height: 17
                }}
                src={backButton}
                alt={""}
            />  
        </button>  
    );
}
